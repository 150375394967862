import { Fragment, useContext, useEffect, useState } from "react";
import { getInfoForCalendar } from "../../../../utils/facility-api/facility-service";
import TimesColumn from "../../../times-column/times-column.component";
import CalendarAreaColumn from '../calendar-area-column/calendar-area-column.component';
import { CalendarSettingsContext } from "../../../../contexts/customer/calendar-settings.context";
import MySportSpaceLoadingView from "../../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import ClickToBook from "../click-to-book/click-to-book.component";
import { Box, Stack } from "@mui/material";
import CalendarAreasHeader from "../calendar-areas-header/calendar-areas-header.component";
import useDeviceDetection from "../../../../utils/device-detection/device-detection";

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const defaultCalendarData = {
    hoursOfOperation: { startDateTime: moment(), endDateTime: moment() },
    facilityTimeZone: "America/Chicago",
    facilityAreas: [],
    numberOfHours: 0,
    timeSlots: 0,
    openBookingTimes: []
}

const Calendar = ({ facilityId, facilityName }) => {

    const device = useDeviceDetection();
    const [isLoading, setIsLoading] = useState(true);
    const { calendarSettings, reservationsDate } = useContext(CalendarSettingsContext);
    const [calenadrData, setCalendarData] = useState(defaultCalendarData);

    useEffect(() => {
        const fetchCalendarInfo = async (facilityId) => {
            setIsLoading(true);
            const calendarInfoResponse = await getInfoForCalendar(facilityId, reservationsDate);
            if (calendarInfoResponse.status === 200 && calendarInfoResponse.data.status === 'success') {
                const { facilityAreas, earliestResStart, latestResEnd, hoursOfOperationStart, hoursOfOperationEnd, facilityTimeZone, openBookingTimes = [] } = calendarInfoResponse.data;
                if (facilityAreas) {
                    let column = 1;
                    for (const fArea of facilityAreas) {
                        fArea.column = column;
                        column += 1;
                    }
                }
    
                const todaysHoursOfOperationEnd = moment(hoursOfOperationEnd, "YYYY-MM-DD h:mm A");
                const todaysHoursOfOperationStart = moment(hoursOfOperationStart, "YYYY-MM-DD h:mm A").startOf('hour');
                let hoursOfOperation = {
                    startDateTime: todaysHoursOfOperationStart,
                    endDateTime: todaysHoursOfOperationEnd,
                    facilityCloseDateTime: todaysHoursOfOperationEnd.clone(),
                    facilityOpenDateTime: moment(hoursOfOperationStart, "YYYY-MM-DD h:mm A")
                }
                if (latestResEnd) {
                    const latestRes = moment(latestResEnd, "YYYY-MM-DD h:mm A");
                    if (latestRes.isAfter(todaysHoursOfOperationEnd)) {
                        hoursOfOperation.endDateTime = latestRes;
                    }
                }
    
    
                if (earliestResStart) {
                    const earliestRes = moment(earliestResStart, "YYYY-MM-DD h:mm A");
                    if (earliestRes.isBefore(todaysHoursOfOperationStart)) {
                        hoursOfOperation.startDateTime = earliestRes.startOf('hour');
                    }
                }
    
                const numberOfHours = hoursOfOperation.endDateTime.clone().endOf('hour').add(1, 'minute').diff(hoursOfOperation.startDateTime, 'hours');
                const newCalendarData = {
                    hoursOfOperation: hoursOfOperation,
                    facilityAreas: facilityAreas,
                    numberOfHours: numberOfHours,
                    facilityTimeZone: facilityTimeZone,
                    timeSlots: (numberOfHours * 4),
                    openBookingTimes: openBookingTimes
                }
                setCalendarData(newCalendarData)
    
            } else {
                setCalendarData(defaultCalendarData);
            }
            setIsLoading(false);
        }
        fetchCalendarInfo(facilityId);
    }, [reservationsDate, facilityId]);

    if (!calenadrData.hoursOfOperation) {
        return null;
    }

    let width = 100;
    const preferredWidth = device === 'Mobile' ? calenadrData.facilityAreas.length * 50 : calenadrData.facilityAreas.length * 16;
    if (preferredWidth > 100) {
        width = preferredWidth;
    }
    const reservationStackWidth = width - 4;

    return (
        <Fragment>
            <ClickToBook reservationsDate={reservationsDate}></ClickToBook>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            {
                !isLoading && calenadrData &&
                <Stack sx={{ overflow: 'scroll' }} position={'fixed'} top={'20.0vh'} left={'0%'} right={'0%'} bottom={'0%'} display={'flex'}>
                    <Stack mb={2} sx={{ width: `${width}vw`, height: `100%` }}>
                        <CalendarAreasHeader facilityAreas={calenadrData.facilityAreas}></CalendarAreasHeader>
                        <Box sx={{ height: '100%', backgroundColor: 'white', overflow: 'visible' }}>
                            <Stack sx={{ height: `100%` }} display={'flex'} direction={'row'}>
                                <TimesColumn mainFacilityColor={calendarSettings ? calendarSettings.mainFacilityColor : 'black'} hoursOfOperation={calenadrData.hoursOfOperation} timeSlots={calenadrData.timeSlots}></TimesColumn>
                                <Stack direction={'row'} sx={{ width: `${reservationStackWidth}vw` }}>
                                    {
                                        calenadrData.facilityAreas.length > 0 && calenadrData.facilityAreas.map((facilityArea) => {
                                            return <CalendarAreaColumn key={facilityArea.id} facilityName={facilityName} facilityId={facilityId} calendarDate={reservationsDate} column={facilityArea.column + 1} calendarData={calenadrData} facilityArea={facilityArea}></CalendarAreaColumn>
                                        })
                                    }
                                </Stack>
                            </Stack>
                        </Box>
                    </Stack>
                </Stack>
            }
        </Fragment >
    )
}

export default Calendar